import './bootstrap';

import.meta.glob(['../images/**', '../fonts/**', '../videos/**']);

import {
	Livewire,
	Alpine,
} from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);

Livewire.start();

window.addEventListener('scroll', () => {
	const header = document.querySelector('header');
	if (header === null) return;
	if (
		(window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0) > header.offsetHeight
	) {
		header.classList.add('bg-white/90', 'shadow');
	} else {
		header.classList.remove('bg-white/90', 'shadow');
	}
});

function setupCounters() {
	const counters = document.querySelectorAll('.count_up_item__value');
	// Main function
	for (let n of counters) {
		const updateCount = () => {
			const target = +n.getAttribute('data-value');
			const count = parseInt(+n.innerText.split('+')[0], 10);
			const speed = 5000;
			const inc = target / speed;
			if (count < target) {
				n.innerText = Math.ceil(count + inc) + ' +';
				setTimeout(updateCount, 50);
			} else {
				n.innerText = target + ' +';
			}
		};
		updateCount();
	}
}

function resetCounters() {
	const counters = document.querySelectorAll('.count_up_item__value');
	// Main function
	for (let n of counters) {
		n.innerText = '';
	}
}

const counterObserver = new IntersectionObserver((elements) => {
	if (elements[0].isIntersecting) {
		setupCounters();
	} else {
		resetCounters();
	}
});

if (document.querySelector('.count_up_item')) {
	const counters = document.querySelector('.count_up_item');
	counterObserver.observe(counters);
}

const animationObserver = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add('show');
		} else {
			entry.target.classList.remove('show');
		}
	});
});

const elementsToAnimate = document.querySelectorAll('.animated');
elementsToAnimate.forEach((el) => animationObserver.observe(el));

const allHeadlines = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
const stopLetters = ['!', '?', ':'];
allHeadlines.forEach((heading) => {
	if (stopLetters.some((v) => heading.innerHTML.endsWith(v))) {
		heading.classList.add('no-after');
	}
});

document.addEventListener('DOMContentLoaded', function () {
	var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

	if ('IntersectionObserver' in window) {
		var lazyVideoObserver = new IntersectionObserver(function (
			entries,
			observer
		) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (
							typeof videoSource.tagName === 'string' &&
							videoSource.tagName === 'SOURCE'
						) {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove('lazy');
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
});
